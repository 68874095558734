import { config, useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';
import { useState, VFC } from 'react';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { DisplayManagerStore } from '../../../../lib/state/display-manager';
import { ReepayStore } from '../../../../lib/state/reepay-form/reepay-form.store';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { useUI } from '../../../ui';
import { CalculateSavings } from '../../calculate-savings';
import { SavingsDetails } from '../../savings-details';
import { CustomerSavingsContent } from '../../../../lib/api/models/umbraco';

type Props = {
    savingsContent: CustomerSavingsContent;
};

export const SavingsContentHandler: VFC<Props> = ({ savingsContent }) => {
    // Savings Store
    const { showSavingsModal } = DisplayManagerStore.useStoreState((store) => store);
    const { setShowSavingsModal } = DisplayManagerStore.useStoreActions((actions) => actions);

    // Savings internal State
    const [showSavingsDetails, setShowSavingsDetails] = useState(false);

    // Reepay
    const { setModalOpen } = ReepayStore.useStoreActions((actions) => actions);

    const savingsDetailsTransitions = useTransition(showSavingsDetails, {
        from: { opacity: 0, display: 'none' },
        enter: { opacity: 1 },
        leave: { opacity: 0, display: 'none' },
        reverse: showSavingsDetails,
        config: config.gentle,
    });

    const uiHandler = useUI();

    const { trackCalculateSavings, trackMembership } = useGtmTracking();
    const calculateSavingsTracker = trackCalculateSavings();
    const membershipTracker = trackMembership();

    const closeSavingsModal = (): void => {
        setShowSavingsModal(false);
        setShowSavingsDetails(false);
        uiHandler.applyScroll();
    };

    const goToSavingsView = (): void => {
        if (!showSavingsModal) {
            calculateSavingsTracker.opened();
            setShowSavingsModal(true);
        }

        setShowSavingsDetails(false);
    };

    const goToDetailsView = (): void => {
        calculateSavingsTracker.viewDetails();
        setShowSavingsModal(true);
        setShowSavingsDetails(true);
    };

    return (
        <SidePanel
            isVisible={showSavingsModal}
            cancelAction={() => {
                closeSavingsModal();
            }}
            scrollToTop={true}
            variant={!showSavingsDetails ? 'md' : 'lg'}
        >
            <SidePanelLayout
                closeSidePanel={() => {
                    if (showSavingsDetails) {
                        goToSavingsView();
                    } else {
                        closeSavingsModal();
                    }
                }}
            >
                {!showSavingsDetails && (
                    <CalculateSavings
                        savingsContent={savingsContent}
                        closeSavings={() => closeSavingsModal()}
                        showSavingsDetails={() => goToDetailsView()}
                    />
                )}

                {savingsDetailsTransitions(({ opacity }, item) =>
                    item ? (
                        <animated.div
                            style={{
                                opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
                            }}
                        >
                            <SavingsDetails
                                content={savingsContent}
                                adjustSavings={() => goToSavingsView()}
                                goToSignUp={() => {
                                    closeSavingsModal();
                                    membershipTracker.openSignUp();
                                    setModalOpen(true);
                                    uiHandler.removeScroll();
                                }}
                            />
                        </animated.div>
                    ) : null
                )}
            </SidePanelLayout>
        </SidePanel>
    );
};

import { FC } from 'react';
import { SvgIcon } from '../../../svg-icon';
import { Close, StyledLayoutWrapper, StyledHeaderSection, StyledMainContent } from './side-panel-layout-mobile.styled';

type SidePanelLayoutMobileProps = {
    closeSidePanel: () => void;
};

export const SidePanelLayoutMobile: FC<SidePanelLayoutMobileProps> = ({ closeSidePanel, children }) => {
    return (
        <StyledLayoutWrapper>
            <StyledHeaderSection>
                <Close
                    onClick={() => {
                        closeSidePanel();
                    }}
                >
                    <SvgIcon iconName={'close'} />
                </Close>
            </StyledHeaderSection>
            <StyledMainContent>{children}</StyledMainContent>
        </StyledLayoutWrapper>
    );
};

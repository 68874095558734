import styled from 'styled-components';
import { StyledCard } from '../shared/product-detail-page-shared.styled';
import { Button } from '../../../shared';
import { device } from '../../../../lib/media-query';

export const StyledTradeInCarCard = styled(StyledCard)`
    padding: 20px;
    margin-top: 20px;
`;

export const StyledTradeInHeader = styled.p`
    font-weight: 600;
    font-size: 14px;
`;

export const StyledTradeInLicenseInput = styled.div`
    margin-top: 15px;
    position: relative;

    label {
        margin-left: 50px;
    }
`;

export const StyledEuLicensePlateLogo = styled.span`
    background-color: #003e99;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2px;
    font-size: 8px;
    border-radius: 6px;
    height: 33px;
    width: 23px;
    position: absolute;
    left: 18px;
    top: 50%;
    transform: translateY(-50%);

    img {
        width: 13px;
    }
`;

export const StyledButton = styled(Button)`
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    @media ${device.mobile_tablet} {
        display: block;
    }
`;

export const StyledExplanationLinkContainer = styled.div`
    margin-top: 15px;
`;

export const StyledExplanationLinkText = styled.span`
    font-size: 14px;
    font-weight: 500;
`;

export const StyledCloseButton = styled(Button)`
    margin-top: 20px;
`;

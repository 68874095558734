import { FC } from 'react';
import { SvgIcon } from '../../../svg-icon';
import { StickyClose, StyledLayoutWrapper, StyledMainContent } from './side-panel-layout-desktop.styled';

type SidePanelLayoutDesktopProps = {
    closeSidePanel: () => void;
};

export const SidePanelLayoutDesktop: FC<SidePanelLayoutDesktopProps> = ({ closeSidePanel, children }) => {
    return (
        <StyledLayoutWrapper>
            <StickyClose
                onClick={() => {
                    closeSidePanel();
                }}
            >
                <SvgIcon iconName={'close'} />
            </StickyClose>
            <StyledMainContent>{children}</StyledMainContent>
        </StyledLayoutWrapper>
    );
};

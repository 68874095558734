import Link from 'next/link';
import { FC } from 'react';
import { SecondaryLinkSection as SecondaryLinkSectionType } from '../../../lib/api/models/umbraco/mega-menu.types';
import { CtaHandler } from '../../plus-sites-shared/content-handlers';
import {
    StyledCtaWrapper,
    StyledSecondaryLink,
    StyledSecondaryLinkSectionHeader,
    StyledSecondaryLinkSectionWrapper,
} from './secondary-link-section.styled';

type IProps = {
    content: SecondaryLinkSectionType;
    onLinkClick: () => void;
};

export const SecondaryLinkSection: FC<IProps> = ({ content, onLinkClick }) => {
    return (
        <StyledSecondaryLinkSectionWrapper>
            <StyledSecondaryLinkSectionHeader>{content.sectionHeader}</StyledSecondaryLinkSectionHeader>
            {content.sectionLinks.map((x, idx) => {
                return (
                    <Link key={idx} href={x.linkDestination.url} passHref>
                        <StyledSecondaryLink onClick={() => onLinkClick()}>{x.linkText}</StyledSecondaryLink>
                    </Link>
                );
            })}
            {content.sectionCTA.length > 0 ? (
                <StyledCtaWrapper>
                    <CtaHandler extraClickActions={() => onLinkClick()} ctas={content.sectionCTA}></CtaHandler>
                </StyledCtaWrapper>
            ) : null}
        </StyledSecondaryLinkSectionWrapper>
    );
};

import { VFC, useCallback, useEffect, useState } from 'react';
import { contentSpotTypes } from '../../../lib/api';
import { TileSpotTile } from '../../../lib/api/models/umbraco/content-spot';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import {
    StyledIconButton,
    StyledModal,
    StyledModalContent,
    StyledModalContentWrapper,
    StyledSpotHeader,
    StyledText,
    StyledTile,
    StyledTileBottomContent,
    StyledTileButton,
    StyledTileModalTop,
    StyledTileSpotCtaWrapper,
    StyledTileText,
    StyledTileTitle,
    StyledTileTopContent,
    StyledTilesList,
} from './benefits-tiles-spot.styled';
import { Close } from '../../icons';
import { CtaHandler } from '../../shared/cta-handler';
import { StyledSpotSpacingContainer } from '../helpers';
import { getCmsImageUrl } from '../../../utils/helpers';

export type BenefitsTilesSpotProps = {
    content: contentSpotTypes.BenefitsTilesSpot;
};

export const BenefitsTilesSpot: VFC<BenefitsTilesSpotProps> = ({ content }) => {
    const [showModal, setShowModal] = useState(false);
    const [currentModalContent, setCurrentModalContent] = useState<TileSpotTile | null>(null);

    const activateModal = (content: TileSpotTile) => {
        setShowModal(true);
        setCurrentModalContent(content);
    };

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            setShowModal(false);
        }
    }, []);

    useEffect(() => {
        if (showModal) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [showModal, handleKeyDown]);

    return (
        <>
            {/* SPOT */}
            <CenteredBlock>
                <StyledSpotSpacingContainer>
                    <StyledSpotHeader headerType={content.headerType} headerSize={content.headerSize}>
                        {content.headerText}
                    </StyledSpotHeader>
                    <StyledText>{content.contentText}</StyledText>

                    {content && content.tiles && (
                        <StyledTilesList>
                            {content.tiles.map((tile: TileSpotTile, index) => {
                                return (
                                    <>
                                        <StyledTile key={index} imageUrl={tile?.icon && tile.icon.src}>
                                            <StyledTileTopContent>
                                                <StyledTileTitle>{tile.tileTitle}</StyledTileTitle>
                                            </StyledTileTopContent>

                                            <StyledTileBottomContent>
                                                <StyledTileText>{tile.tileText}</StyledTileText>
                                                {tile.modalText && <StyledTileButton onClick={() => activateModal(tile)}>Læs mere</StyledTileButton>}
                                            </StyledTileBottomContent>
                                        </StyledTile>
                                    </>
                                );
                            })}
                        </StyledTilesList>
                    )}

                    {content.cta && (
                        <StyledTileSpotCtaWrapper>
                            <CtaHandler ctas={content.cta} showAsBlock={true}></CtaHandler>
                        </StyledTileSpotCtaWrapper>
                    )}
                </StyledSpotSpacingContainer>
            </CenteredBlock>

            {/* MODAL */}
            {showModal && currentModalContent && (
                <StyledModal isVisible={showModal} onClick={() => setShowModal(false)}>
                    <StyledModalContentWrapper isVisible={showModal} onClick={(e) => e.stopPropagation()}>
                        <StyledIconButton label="Luk" size={45} onClick={() => setShowModal(false)}>
                            <Close />
                        </StyledIconButton>
                        <StyledModalContent>
                            <StyledTileModalTop>
                                {currentModalContent?.icon && (
                                    <img src={getCmsImageUrl(currentModalContent.icon.src)} alt={currentModalContent.icon.name} />
                                )}
                                <strong>{currentModalContent.modalTitle}</strong>
                            </StyledTileModalTop>
                            <div dangerouslySetInnerHTML={{ __html: currentModalContent.modalText ?? '' }}></div>
                        </StyledModalContent>
                    </StyledModalContentWrapper>
                </StyledModal>
            )}
        </>
    );
};

import { FC, useEffect, useState } from 'react';
import { StyledBackLinkWrapper, StyledCheckoutGrid, StyledCheckoutWrapper, StyledStepsList } from './shop-checkout-page.styled';
import { CenteredBlock } from '../../structural-blocks/centered-block.component';
import { useRouter } from 'next/router';
import { SvgIcon } from '../../shared/svg-icon';
import { CheckoutSummary } from './checkout-summary/checkout-summary.component';
import { ShopCustomerInfo } from './customer-info/shop-customer-info.component';
import { ShopDeliveryStep } from './delivery-step/shop-delivery-step.component';
import { ShopPaymentStep } from './payment-step/shop-payment-step.component';
import { ShopReceiptStep } from './receipt-step/shop-receipt-step.component';
import { CartStoreContext } from '../../../lib/state/shop/cart/cart.store';
import { ShopCheckoutPage as ShopCheckoutPageType, sharedTypes } from '../../../lib/api/models/umbraco';

export type ShopStepProps = {
    currentStep: number;
    setActiveStep: (step: number) => void;
    page: ShopCheckoutPageType;
};

type IProps = {
    page: ShopCheckoutPageType;
    paymentIcons: Array<sharedTypes.Image>;
};

export const ShopCheckoutPage: FC<IProps> = ({ page, paymentIcons }) => {
    const { cart, shopCheckoutComplete, cartReceipt } = CartStoreContext.useStoreState((state) => state);
    const { resetShopStore } = CartStoreContext.useStoreActions((actions) => actions);
    const [activeStep, setActiveStep] = useState(1);

    const router = useRouter();

    useEffect(() => {
        //This is used to reset our shop store when checkout is complete and we navigate away from the checkout flow
        const routerEventReset = () => {
            resetShopStore();
            router.events.off('routeChangeComplete', routerEventReset);
        };
        if (shopCheckoutComplete) {
            router.events.on('routeChangeComplete', routerEventReset);
        }
    }, [resetShopStore, router, shopCheckoutComplete]);

    useEffect(() => {
        if (activeStep !== 1) {
            setTimeout(() => {
                document.getElementById(`shop-step-${activeStep}`)?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }, 500);
        }
    }, [activeStep]);

    return (
        <StyledCheckoutWrapper>
            <CenteredBlock>
                {cart || (cartReceipt && shopCheckoutComplete) ? (
                    <>
                        <StyledBackLinkWrapper onClick={() => router.back()}>
                            <SvgIcon iconName={'chevron/left'} />
                            <span>Tilbage</span>
                        </StyledBackLinkWrapper>
                        <StyledCheckoutGrid>
                            <StyledStepsList>
                                <div id={`shop-step-1`}>
                                    <ShopCustomerInfo currentStep={activeStep} setActiveStep={(step) => setActiveStep(step)} page={page} />
                                </div>
                                <div id={`shop-step-2`}>
                                    <ShopDeliveryStep currentStep={activeStep} setActiveStep={(step) => setActiveStep(step)} page={page} />
                                </div>
                                <div id={`shop-step-3`}>
                                    <ShopPaymentStep
                                        currentStep={activeStep}
                                        setActiveStep={(step) => setActiveStep(step)}
                                        page={page}
                                        paymentIcons={paymentIcons}
                                    />
                                </div>
                                <div id={`shop-step-4`}>
                                    <ShopReceiptStep currentStep={activeStep} setActiveStep={(step) => setActiveStep(step)} page={page} />
                                </div>
                            </StyledStepsList>
                            {!shopCheckoutComplete ? <CheckoutSummary /> : null}
                        </StyledCheckoutGrid>
                    </>
                ) : (
                    <p style={{ fontWeight: 700, fontSize: '40px', fontFamily: 'Corporate E', padding: '40px 0' }}>Din kurv er tom</p>
                )}
            </CenteredBlock>
        </StyledCheckoutWrapper>
    );
};

import styled, { css } from 'styled-components';
import { device } from '../../../../../lib/media-query';

export const StyledUserZipWrapper = styled.div`
    max-width: 210px;
`;

export const StyledPickupDestinationsWrapper = styled.div`
    margin-top: 30px;
    border-top: 1px solid #e3e3e5;
    margin-bottom: 20px;
    max-height: 350px;
    overflow-y: scroll;
    padding-right: 10px;
    position: relative;
`;

export const StyledScrollMoreIndicator = styled.div`
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 22%, rgba(255, 255, 255, 0) 100%);
`;

export const StyledPickupDestination = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e5;
    padding: 20px 0;
    transition: all 0.05s ease-in-out;
    ${(props) =>
        props.selected &&
        css`
            border-left: 5px solid #00adef;
            padding-left: 20px;
        `}
    @media ${device.tablet} {
        flex-direction: row;
    }
`;

export const StyledPickupDestinationHeader = styled.p`
    font-weight: 700;
    font-family: 'Corporate E';
    font-size: 17px;
`;

export const StyledSpinnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const StyledDetailsModalOpener = styled.p`
    color: #807f80;
    text-decoration: underline;
    cursor: pointer;
    font-size: 12px;
    display: inline;
`;

export const StyledDetailsHeader = styled.p`
    font-family: 'Corporate E';
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 20px;
`;

import styled from 'styled-components';
import { RadioButton } from '../../../shared';
import { device } from '../../../../lib/media-query';

export const StyledDeliveryOptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
`;

export const StyledDeliveryOption = styled.div`
    border: 1px solid #e2e2e4;
    padding: 20px 25px;
`;

export const StyledDeliveryOptionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
`;

export const StyledRadioButton = styled(RadioButton)`
    label {
        font-size: 14px;
        font-weight: 400;
    }

    p {
        font-size: 12px;
        font-weight: 500;
        color: #807f80;
    }
`;

export const StyledDeliveryOptionWrapper = styled.div`
    margin-top: 15px;
    @media ${device.mobile_tablet} {
        padding-left: 27px; //beware, magic number
    }
`;

export const StyledDeliveryOptionPrice = styled.p`
    flex-shrink: 0;
`;

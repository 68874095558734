import styled, { css } from 'styled-components';
import { device } from '../../../lib/media-query';
import { isSiteName, SITE_NAME } from '../../../utils/helpers/site-id.helper';

export const BorderBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding-left: 10px;
    padding-bottom: 150px;

    @media ${device.tablet} {
        gap: 1.2rem;
    }

    @media ${device.desktop} {
        padding-bottom: 200px;
    }
`;

export const Header = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.primary};
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 25px;
    ${isSiteName(SITE_NAME.CARSAVER) &&
    css`
        font-weight: 900;
    `}
`;

export const StyledText = styled.p`
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    color: #0b0b0b;
    font-weight: 400;
`;

export const Footnote = styled.p`
    max-width: 361px;
    opacity: 0.5;
    color: #0b0b0b;
    font-family: ${({ theme }) => theme.fontFamily.secondary};
    font-size: 11px;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 0.4rem;
`;

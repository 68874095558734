import styled from 'styled-components';
import { device } from '../../../lib/media-query';

export const StyledSpotWrapper = styled.div`
    background-color: #f7f7f8;
    padding: 40px 0;
    overflow-x: hidden;
    position: relative;
`;

export const StyledPreviousCategoryLink = styled.a`
    color: black;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;

    &:hover {
        text-decoration: none;
    }

    svg {
        transform: scale(0.75);
    }
`;

export const StyledOtherCategoriesWrapper = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 20px;
    @media ${device.mobile_tablet} {
        margin-top: 30px;
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const StyledOtherCategory = styled.a<{ active: boolean }>`
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: ${(props) => (props.active ? '#0089D1' : 'black')};
    font-size: 12px;
    align-items: center;
    max-width: 65px;
    line-height: 12px;
    text-align: center;

    &:hover {
        text-decoration: none;
    }

    @media ${device.mobile_tablet} {
        max-width: 75px;
    }

    span:first-of-type {
        border: 1px solid ${(props) => (props.active ? '#0089D1' : 'white')};
        border-radius: 50%;
        height: 65px;
        width: 65px;
        @media ${device.mobile_tablet} {
            height: 75px;
            width: 75px;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        height: 65px;
        width: 65px;
        border: 4px solid white;
        border-radius: 50%;
        overflow: hidden;

        @media ${device.mobile_tablet} {
            height: 75px;
            width: 75px;
        }
    }
`;

export const StyledProductListFacetsAndProductsWrapper = styled.div`
    margin-top: 20px;
    @media ${device.laptopS} {
        display: grid;
        gap: 60px;
        grid-template-columns: 1fr 3.5fr;
    }
`;

export const StyledProductCardWrapper = styled.main`
    display: grid;
    gap: 20px;
    margin-top: 20px;
    @media ${device.mobile_tablet} {
        grid-template-columns: repeat(2, 1fr);
    }
    @media ${device.laptop} {
        grid-template-columns: repeat(3, 1fr);
    }
`;

export const StyledProductCount = styled.p`
    font-size: 17px;
    color: #807f80;
`;

export const StyledFilterTilesWrapper = styled.div`
    margin-top: 20px;
`;

export const StyledResultsTextAndSortingWrapper = styled.div<{ spacing: number }>`
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    margin-top: ${(props) => `${props.spacing}px`};

    @media ${device.tablet} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

import { deleteAPI, getAPI, postAPI, putAPI } from '../helpers';
import {
    CartCustomer,
    CartObject,
    DealershipPickupRequest,
    HomeDeliveryAlternativeAddress,
    HomeDeliveryRequest,
    ParcelShopPickupRequest,
    ReepayCheckoutRequest,
    ReepayCheckoutResponse,
} from '../models/shop/cart';
import { DeliveryOptionType } from '../models/shop/shop-api.models';
import { APIResponse } from '../types';
import { EHApiGatewayControllersProductDetailsParameter } from '../../api-types';
import { ParcelShop } from '../models/shop/parcel-shops';
import { DealershipInformationPage } from '../models/umbraco';

export const addProductToCart = async (productSku: string, cartId: string | null): APIResponse<CartObject> => {
    const postUrl = cartId ? `/api/v2/commerce/basket/${cartId}/sku/${productSku}` : `/api/v2/commerce/basket/sku/${productSku}`;
    return postAPI<CartObject>(postUrl, {
        quantity: 1,
    });
};

export const updateOrderlineQuantity = async (productSku: string, quantity: number, cartId: string): APIResponse<CartObject> => {
    const body: EHApiGatewayControllersProductDetailsParameter = {
        quantity: quantity,
        installation: null,
    };
    return putAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/sku/${productSku}`, body);
};

export const deleteOrderline = async (productSku: string, cartId: string): APIResponse<CartObject> => {
    return deleteAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/sku/${productSku}`);
};

export const addCustomerToCart = async (customerInfo: CartCustomer, cartId: string): APIResponse<CartObject> => {
    return postAPI<CartObject>(`/api/v2/commerce/basket/${cartId}/customer`, customerInfo);
};

export const startCheckoutPayment = async (request: ReepayCheckoutRequest): APIResponse<ReepayCheckoutResponse> => {
    return postAPI<ReepayCheckoutResponse>(`/api/v1/payment/startcheckout`, request);
};

export const getDeliveryOptions = async (cartId: string): APIResponse<DeliveryOptionType[]> => {
    return getAPI<DeliveryOptionType[]>(`/api/v2/commerce/delivery-options/${cartId}`);
};

export const setDealershipAsDelivery = async (cartId: string, dealership: DealershipInformationPage): APIResponse<CartObject> => {
    const requestBody: DealershipPickupRequest = {
        pickUpAtHessel: {
            shippingMethod: 'PickUp_at_Hessel',
            pickUpAddress: {
                addressLine1: dealership.address,
                city: dealership.city,
                pickUpId: dealership.id,
                zipCode: dealership.zipcode,
                companyName: dealership.displayName,
            },
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};

export const setParcelShopPickupAsDelivery = async (cartId: string, parcelShop: ParcelShop): APIResponse<CartObject> => {
    const requestBody: ParcelShopPickupRequest = {
        sendToPickUpPoint: {
            shippingMethod: 'PickUp_Point',
            pickUpAddress: {
                addressLine1: parcelShop.address,
                city: parcelShop.city,
                pickUpId: parcelShop.id,
                zipCode: parcelShop.zipcode,
                companyName: parcelShop.name,
            },
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};

export const setHomeDeliveryAsDelivery = async (
    cartId: string,
    alternativeDeliveryAddres: HomeDeliveryAlternativeAddress | null
): APIResponse<CartObject> => {
    const requestBody: HomeDeliveryRequest = {
        sendToAddress: {
            shippingMethod: 'Send_to_Address',
            deliveryAddress: alternativeDeliveryAddres,
            deliveryCustomerName: alternativeDeliveryAddres?.name,
        },
    };
    return postAPI<CartObject>(`/api/v2/commerce/delivery-options/${cartId}`, requestBody);
};

import { isToday, parseISO } from 'date-fns';
import React, { VFC } from 'react';
import { useAccordionController } from '../../../hooks/use-accordion-controller';
import { orgTypes } from '../../../lib/api/models/umbraco';
import { DateStyle, formatDate, isDealershipOpen, mapOpeningHours } from '../../../utils/helpers';
import { SimpleAccordion } from '../simple-accordion';
import { OpeningHoursAccordionHeader } from './header/header.component';
import { StyledText, DayAndDate, FromAndTo, OpeningHoursWrapper, StyledOpeningHours, AccordionItems } from './opening-hours.styled';
import { SpecialDay } from '../../../lib/api/models/umbraco/organization.types';

type OpeningHoursProps = {
    departments: Array<orgTypes.DealershipDepartment>;
    defaultSpecialDays?: Array<SpecialDay>;
    openDepartmentIndex?: number;
};

export const OpeningHours: VFC<OpeningHoursProps> = ({ departments, openDepartmentIndex = -1, defaultSpecialDays }) => {
    const { registerAccordion } = useAccordionController(openDepartmentIndex);

    const mappedDepartments = React.useMemo(
        () =>
            mapOpeningHours(
                departments.filter((x) => x.openingHours?.length > 0),
                defaultSpecialDays
            ),
        [defaultSpecialDays, departments]
    );

    const OpeningUI = (dayOfWeek: Date, opening: orgTypes.OpeningHour) => {
        return (
            <>
                <StyledText showAsBold={isToday(dayOfWeek)}>{formatDate(parseISO(opening.from), DateStyle.HH_mm)}&nbsp;-&nbsp;</StyledText>
                <StyledText showAsBold={isToday(dayOfWeek)}>{formatDate(parseISO(opening.to), DateStyle.HH_mm)}</StyledText>
            </>
        );
    };

    const ClosingHourUI = (dayOfWeek: Date) => {
        return <StyledText showAsBold={isToday(dayOfWeek)}>Lukket</StyledText>;
    };

    return (
        <StyledOpeningHours>
            {mappedDepartments.map((currentDepartment, index) => {
                const todaysOpening = currentDepartment.openingHours.find((x) => isToday(x.calculatedDate));
                return (
                    <SimpleAccordion
                        key={`opening-hour:${currentDepartment.departmentName}:${index}`}
                        title={''}
                        border={index === departments.length - 1 || mappedDepartments.length === 1 ? 'top and bottom' : 'top'}
                        headerSize="0px"
                        textColor={'0B0B0B'}
                        {...registerAccordion(index)}
                        contentPadding={'20.5px 0px'}
                        toggleIndicator="plus"
                        customTitle={
                            <OpeningHoursAccordionHeader
                                openingHour={todaysOpening}
                                label={currentDepartment.departmentName}
                                isOpen={
                                    !todaysOpening?.closed && isDealershipOpen(parseISO(todaysOpening?.from ?? ''), parseISO(todaysOpening?.to ?? ''))
                                }
                            />
                        }
                    >
                        <AccordionItems>
                            {currentDepartment.openingHours.map((opening, index) => {
                                return (
                                    <OpeningHoursWrapper key={index}>
                                        <DayAndDate>
                                            <StyledText showAsBold={isToday(opening.calculatedDate)}>
                                                {isToday(opening.calculatedDate) ? 'Idag' : opening.dayName}
                                            </StyledText>
                                            <StyledText showAsBold={isToday(opening.calculatedDate)}>
                                                {formatDate(opening.calculatedDate, DateStyle.simple_danish_with_dot)}
                                            </StyledText>
                                        </DayAndDate>
                                        <FromAndTo>
                                            {!opening.closed ? OpeningUI(opening.calculatedDate, opening) : ClosingHourUI(opening.calculatedDate)}
                                        </FromAndTo>
                                    </OpeningHoursWrapper>
                                );
                            })}
                        </AccordionItems>
                    </SimpleAccordion>
                );
            })}
        </StyledOpeningHours>
    );
};

import { addDays } from 'date-fns';
import router from 'next/router';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { getPageById, postUmbracoForm } from '../../../lib/api';
import {
    Condition,
    FormGroup,
    FormPage,
    FormPostRequest,
    FormsInput,
    FormsTrackingStrategy,
    UmbracoForm,
    ReepayCancellationStrategy,
    SpecialFormProps,
    BookTestDriveStrategy,
    HireFormSubmitData,
    ContactHesselStrategy,
    SixPlusSixContactStrategy,
} from '../../../lib/api/models/umbraco';
import { cmsUrlWithoutSiteId } from '../../../utils/helpers';
import {
    evaluateFormsCondition,
    getCustomHiddenInputFieldValue,
    getDefaultValue,
    getFieldTypeAlias,
    validateInput,
} from '../../../utils/helpers/umbraco-forms.helper';
import { Button } from '../../shared';
import { FormGroupComponent } from './group/forms-group.component';
import {
    FormHeader,
    FormWrapper,
    StepIndicator,
    StepIndicatorWrapper,
    StepIndicatorContainer,
    ButtonRow,
    UmbracoFormHeader,
} from './umbraco-form.styled';

export type UmbracoFormsProps = {
    form: UmbracoForm;
    header?: string;
    subtext?: string | React.ReactNode;
    pageId: string;
    trackingStrategy?: FormsTrackingStrategy;
    onFormComplete?: () => void;
    scrollAnchorId?: string;
    specialProps?: SpecialFormProps;
    submitExtraPayload?: unknown;
    componentIdSuffix?: string;
};

export const UmbracoForms: FC<UmbracoFormsProps> = ({
    header = '',
    subtext = '',
    pageId,
    form,
    trackingStrategy,
    onFormComplete,
    scrollAnchorId,
    specialProps,
    submitExtraPayload,
    componentIdSuffix,
}) => {
    const [umbracoForm, setUmbracoForm] = useState<UmbracoForm>(form);
    const [currentPage, setCurrentPage] = useState(0);
    const [formLoading, setFormLoading] = useState(false);
    const [formId, setFormId] = useState('');

    const [trackingStarted, setTrackingStarted] = useState(false);
    const { trackMembership, trackBookTestDriveForm, trackContactHesselForm, trackSixPlusSixContactForm } = useGtmTracking();
    const membershipTracker = trackMembership();
    const bookTestDriveTracker = trackBookTestDriveForm();
    const contactHesselTracker = trackContactHesselForm();
    const sixPlusSixContactTracker = trackSixPlusSixContactForm();

    useEffect(() => {
        if (!form) {
            return;
        }

        const newForm = form;
        newForm.pages.forEach((page, index) => {
            page.id = 'id-' + index;
            const inputs = getAllPageInputs(page);
            inputs.forEach((input) => {
                const fieldTypeAlias = getFieldTypeAlias(input.fieldTypeId);
                const defaultValue = getDefaultValue(input);
                if (fieldTypeAlias === 'date') {
                    const day = new Date();
                    day.setHours(0, 0, 0, 0);
                    input.value = addDays(day, 1).toLocaleDateString('en-US');
                } else {
                    input.value = input.value ? input.value : defaultValue;
                }
                input.valid = validateInput(input);
                input.canValidate = false;
            });
        });
        newForm.formCompleted = false;
        setUmbracoForm(newForm);
        setFormId(Math.random().toString(36));
    }, [form]);

    const allInputs = useMemo(() => {
        const inputs: FormsInput[] = [];
        umbracoForm?.pages.forEach((page) => {
            page.fieldSets.forEach((group) => {
                group.containers.forEach((column) => {
                    column.fields.forEach((input) => {
                        inputs.push(input);
                    });
                });
            });
        });
        return inputs;
    }, [umbracoForm]);

    const updateGroup = (formPage: FormPage, formGroup: FormGroup) => {
        if (umbracoForm) {
            const newPage = umbracoForm.pages.find((x) => x.id === formPage.id);
            if (newPage) {
                const updatedColumns = newPage.fieldSets.map((fs) => (fs.id === formGroup.id ? formGroup : fs));
                newPage.fieldSets = updatedColumns;
                const updatedPages = umbracoForm.pages.map((x) => (x.id === formPage.id ? newPage : x));

                const cancellationStrategy = trackingStrategy as ReepayCancellationStrategy;
                if (cancellationStrategy && cancellationStrategy.alias === 'reepayCancellation' && !trackingStarted) {
                    setTrackingStarted(true);
                    membershipTracker.openCancellation();
                }

                setUmbracoForm({ ...umbracoForm, pages: updatedPages });
            }
        }
    };

    const getAllPageInputs = (formPage: FormPage) => {
        const allPageInputs: FormsInput[] = [];
        formPage.fieldSets.forEach((fs) => {
            fs.containers.forEach((col) => {
                col.fields.forEach((f) => {
                    allPageInputs.push(f);
                });
            });
        });
        return allPageInputs;
    };

    const setCurrentPageIndex = (newPageIndex: number) => {
        //Scroll to top of page on change the page index. SetTimeouts for UX purposes.
        setFormLoading(true);
        document.getElementById(`formContainer-${formId}`)?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            setCurrentPage(newPageIndex);
        }, 1000);
        setTimeout(() => {
            setFormLoading(false);
        }, 1200);
    };

    const formPageSubmit = (page: FormPage) => {
        let anyInputErrors = false;
        //Check for errors
        page.fieldSets.forEach((group) => {
            const newGroup = group;
            newGroup.containers = group.containers.map((column) => {
                const newInputs = column.fields.map((input) => {
                    if (!input.valid && evaluateFormsCondition(input.condition, allInputs)) {
                        anyInputErrors = true;
                        return { ...input, canValidate: true };
                    }
                    return input;
                });
                return { ...column, fields: newInputs };
            });
            updateGroup(page, newGroup);
        });
        //Go to next page or submit form
        if (!anyInputErrors && umbracoForm) {
            if (currentPage + 1 < umbracoForm.pages.length) {
                setCurrentPageIndex(currentPage + 1);
            } else {
                submitForm();
            }
        } else {
            //Scroll to first invalid input if form contains invalid inputs
            const scrollTarget = allInputs.find((x) => x.valid === false);
            if (scrollTarget) {
                setTimeout(() => {
                    document
                        .getElementById(`formContainer-${formId}`)
                        ?.querySelector(`#input-${scrollTarget.id}`)
                        ?.scrollIntoView({ behavior: 'smooth' });
                }, 100);
            }
        }
    };

    const getCtaText = (): string => {
        if (currentPage + 1 === umbracoForm?.pages.length) {
            return umbracoForm.submitLabel;
        }
        return umbracoForm?.nextLabel ?? 'Næste';
    };

    const submitForm = async () => {
        if (umbracoForm) {
            setFormLoading(true);
            const umbracoFormsRequest: FormPostRequest = {
                PageId: pageId,
                FormId: umbracoForm.id,
                Fields: [],
            };
            for (const page of umbracoForm.pages) {
                for (const group of page.fieldSets) {
                    for (const column of group.containers) {
                        for (const input of column.fields) {
                            if (evaluateFormsCondition(input.condition, allInputs)) {
                                if (getFieldTypeAlias(input.fieldTypeId) === 'fileUpload' && input.file) {
                                    const base64 = await toBase64(input.file);
                                    if (base64) {
                                        umbracoFormsRequest.Fields.push({
                                            FieldId: input.id,
                                            FieldFiles: [
                                                {
                                                    Name: input.file.name,
                                                    Base64: base64.toString(),
                                                },
                                            ],
                                        });
                                    }
                                } else if (getFieldTypeAlias(input.fieldTypeId) === 'hidden') {
                                    umbracoFormsRequest.Fields.push({
                                        FieldId: input.id,
                                        FieldValues: [getCustomHiddenInputFieldValue(input, specialProps)],
                                    });
                                } else if (
                                    getFieldTypeAlias(input.fieldTypeId) === 'dataConsent' ||
                                    getFieldTypeAlias(input.fieldTypeId) === 'checkbox'
                                ) {
                                    umbracoFormsRequest.Fields.push({
                                        FieldId: input.id,
                                        FieldValues: [!input.value || input.value === '' ? 'false' : input.value],
                                    });
                                } else if (getFieldTypeAlias(input.fieldTypeId) !== 'titleAndDescription') {
                                    umbracoFormsRequest.Fields.push({
                                        FieldId: input.id,
                                        FieldValues: [input.value],
                                    });
                                }
                            }
                        }
                    }
                }
            }

            const response = await postUmbracoForm(umbracoFormsRequest);

            if (response) {
                if (trackingStrategy && (trackingStrategy as ReepayCancellationStrategy)?.alias === 'reepayCancellation') {
                    const actionInput = allInputs.find((x) => x.alias === trackingStrategy.action);
                    if (actionInput) {
                        membershipTracker.completeCancellation(actionInput.value);
                    }
                }
                if (trackingStrategy && (trackingStrategy as BookTestDriveStrategy)?.alias === 'bookTrialEvent') {
                    const actionInput = allInputs.find((x) => x.alias === trackingStrategy.action);
                    if (actionInput) {
                        const extraData = submitExtraPayload as HireFormSubmitData;
                        bookTestDriveTracker.completeBookTestDrive({ ...extraData, afdeling: actionInput.value });
                    }
                }
                if (trackingStrategy && (trackingStrategy as ContactHesselStrategy)?.alias === 'kontaktHessel') {
                    const extraData = submitExtraPayload as HireFormSubmitData;
                    contactHesselTracker.completeContactHesselForm({ ...extraData });
                }
                if (trackingStrategy && (trackingStrategy as SixPlusSixContactStrategy)?.alias === 'plus6BundleContact') {
                    const actionInput = allInputs.find((x) => x.alias === trackingStrategy.action);
                    if (actionInput) {
                        sixPlusSixContactTracker.completeContactSixPlusSixFormEvents(actionInput.value);
                    }
                }
                if (umbracoForm.goToPageOnSubmit) {
                    const [response, error] = await getPageById(umbracoForm.goToPageOnSubmit);
                    if (response && !error) {
                        const redirectUrl = cmsUrlWithoutSiteId(response[0].url);
                        router.push(redirectUrl);
                    }
                } else {
                    document.getElementById(`formContainer-${formId}`)?.scrollIntoView({ behavior: 'smooth' });
                    setTimeout(() => {
                        setUmbracoForm({ ...umbracoForm, formCompleted: true });
                        onFormComplete?.();
                    }, 1000);
                    setTimeout(() => {
                        setFormLoading(false);
                    }, 1200);
                }
            }
        }
    };

    const toBase64 = (file: File) =>
        new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    if (!umbracoForm) {
        return null;
    }

    return (
        <FormWrapper formLoading={formLoading} id={scrollAnchorId}>
            <section id={`formContainer-${formId}`}>
                {!header && !subtext ? null : (
                    <FormHeader>
                        {header.length > 0 && <UmbracoFormHeader>{header}</UmbracoFormHeader>}
                        {typeof subtext === 'string' && subtext.length > 0 ? <div dangerouslySetInnerHTML={{ __html: subtext }}></div> : null}
                        {subtext && React.isValidElement(subtext) ? subtext : null}
                    </FormHeader>
                )}

                {umbracoForm.pages.length > 1 && (
                    <StepIndicatorContainer>
                        {umbracoForm.pages.map((p, index) => {
                            return (
                                <StepIndicatorWrapper active={currentPage === index} complete={false} key={index}>
                                    <StepIndicator active={currentPage === index} complete={false}>
                                        {index + 1}
                                    </StepIndicator>
                                    <p>{p.caption}</p>
                                </StepIndicatorWrapper>
                            );
                        })}
                    </StepIndicatorContainer>
                )}
                {umbracoForm.formCompleted && <div>{umbracoForm.messageOnSubmit}</div>}
                {!umbracoForm.formCompleted &&
                    umbracoForm.pages.map((page, index) => {
                        if (currentPage === index) {
                            return (
                                <section key={`formspage-${index}`}>
                                    {page.fieldSets.map((fs, fsIndex) => {
                                        if (!evaluateFormsCondition(fs.condition, allInputs)) {
                                            return null;
                                        }
                                        return (
                                            <FormGroupComponent
                                                key={fsIndex}
                                                formGroup={fs}
                                                conditionEvaluator={(condition: Condition) => evaluateFormsCondition(condition, allInputs)}
                                                onGroupChange={(fg) => updateGroup(page, fg)}
                                                componentIdSuffix={componentIdSuffix}
                                                dropdownColumns={3}
                                            />
                                        );
                                    })}
                                    <ButtonRow>
                                        {currentPage > 0 && (
                                            <Button onClick={() => setCurrentPageIndex(currentPage - 1)}>{umbracoForm.prevLabel}</Button>
                                        )}
                                        {currentPage + 1 <= umbracoForm.pages.length && (
                                            <Button
                                                variant="primary"
                                                onClick={() => {
                                                    formPageSubmit(page);
                                                }}
                                            >
                                                {getCtaText()}
                                            </Button>
                                        )}
                                    </ButtonRow>
                                </section>
                            );
                        }
                        return null;
                    })}
            </section>
        </FormWrapper>
    );
};

import { FC, useMemo, useState } from 'react';
import { StyledCarouselContainer, StyledZoomButton } from '../../../product-details/product-details-page/product-details-page.styled';
import { CompactCarousel } from '../../../shared/carousels/compact-carousel/compact-carousel.component';
import { SimpleCarousel } from '../../../shared/carousels/simple-carousel/simple-carousel.component';
import { TabbedCarouselDialog } from '../../../shared/carousels/tabbed-carousel/tabbed-carousel-dialog.component';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { ShopProductResource } from '../../../../lib/api/models/shop';
import { hesselViewModels } from '../../../../lib/view-models';
import { getImageCropUrl } from '../../../../utils/helpers';

type Props = {
    images: ShopProductResource[];
};

export const CarouselSection: FC<Props> = ({ images }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [sliderDialogOpen, setSliderDialogOpen] = useState(false);
    const isMobile = useMediaQuery({ target: 'tablet' });

    const mappedFullSizeImages: Array<hesselViewModels.PdpResource> = useMemo(() => {
        return images.map((x) => {
            return {
                type: 'image',
                url: getImageCropUrl(x.data, 'productdetailsfullsize'),
            };
        });
    }, [images]);

    const mappedImages: Array<hesselViewModels.PdpResource> = useMemo(() => {
        return images.map((x) => {
            return {
                type: 'image',
                url: getImageCropUrl(x.data, 'productdetails'),
            };
        });
    }, [images]);

    return (
        <div>
            <StyledCarouselContainer>
                {isMobile ? (
                    <CompactCarousel
                        slides={mappedImages}
                        isAboveFold={true}
                        blendMode={{
                            backgroundColor: 'rgb(247,247,248)',
                            mixBlendMode: 'multiply',
                        }}
                        showAsCover={false}
                    />
                ) : (
                    <SimpleCarousel
                        renderControls={(activeIndex) => (
                            <StyledZoomButton
                                label="Åben fuldskærmsvisning"
                                onClick={() => {
                                    setActiveSlide(activeIndex);
                                    setSliderDialogOpen(true);
                                }}
                                size={65}
                            />
                        )}
                        slides={mappedImages}
                        isAboveFold={true}
                        blendMode={{
                            backgroundColor: 'rgb(247,247,248)',
                            mixBlendMode: 'multiply',
                        }}
                        onThumbnailClick={(index) => {
                            setActiveSlide(index);
                            setSliderDialogOpen(true);
                        }}
                    />
                )}
            </StyledCarouselContainer>
            <TabbedCarouselDialog
                initialIndex={activeSlide}
                isVisible={sliderDialogOpen}
                onClose={() => setSliderDialogOpen(false)}
                slides={mappedFullSizeImages}
                blendMode={{
                    backgroundColor: 'rgb(247,247,248)',
                    mixBlendMode: 'multiply',
                }}
            />
        </div>
    );
};

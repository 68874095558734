import { FC, useEffect, useMemo } from 'react';
import { SidePanel } from '../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { StyledCloseButton } from './trade-in-car.styled';
import { ProductDetailsStore } from '../../../../lib/state/hessel-site/product-details';

type IProps = {
    visible: boolean;
    onClose: () => void;
};

export const TradeInCarAutoProffModal: FC<IProps> = ({ visible, onClose }) => {
    const { selectedProduct } = ProductDetailsStore.useStoreState((state) => state);
    const groupId = useMemo(() => {
        if (!selectedProduct) return '';
        if (selectedProduct.availability === 'Used') return '46320';
        if (selectedProduct.brand === 'Mercedes-Benz') return '46490';
        if (selectedProduct.brand === 'Dacia' || selectedProduct.brand === 'Renault') return '46491';
        return '';
    }, [selectedProduct]);
    useEffect(() => {
        const autoProffInit = (reInit: boolean) => {
            window.autoproff({
                group: groupId,
                style: {
                    '.ap-popup-stepbutton:not(.ap-popup-stepbutton-outline)': { 'background-color': '#00ADEF', 'border-color': '#00ADEF' },
                    '.ap-popup-stepcompleted-license': { 'background-color': '#00ADEF' },
                    '.ap-popup-step__badge': { 'background-color': '#00ADEF' },
                    '.ap-popup-step__button': { 'background-color': '#00ADEF', 'border-color': '#00ADEF' },
                    '.ap-popup-step__button:focus': { 'box-shadow': 'none' },
                    // prettier-ignore
                    '.ap-popup-step__button ap-popup-step__button--link': { 'color': '#00ADEF' },
                },
                motor: 'dmr',
                locale: 'da',
                embed_selector: '.ap-embed',
                integrated_button_selector: '.ap-cta',
                autoopen: false,
                button: false,
                reinitialize: reInit,
            });
        };
        const autoProffScriptCheck = document.getElementById('autoProffScript');
        if (visible) {
            if (!autoProffScriptCheck) {
                const autoProffScript = document.createElement('script');
                autoProffScript.setAttribute('src', 'https://webkit.autoproff.com/js/widget.js?v=1720100239454');
                autoProffScript.id = 'autoProffScript';
                document.head.appendChild(autoProffScript);
                autoProffScript.onload = () => autoProffInit(false);
            } else {
                autoProffInit(true);
            }
        }
    }, [groupId, visible]);
    return (
        <SidePanel isVisible={visible} cancelAction={onClose}>
            <SidePanelLayout closeSidePanel={onClose}>
                <div className="ap-embed"></div>
                <StyledCloseButton variant="primary" onClick={onClose}>
                    LUK
                </StyledCloseButton>
            </SidePanelLayout>
        </SidePanel>
    );
};

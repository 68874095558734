import { FC } from 'react';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { Responsive } from '../../responsive';
import { SidePanelLayoutDesktop } from './desktop/side-panel-layout-desktop';
import { SidePanelLayoutMobile } from './mobile/side-panel-layout-mobile';

type SidePanelLayoutProps = {
    ctaComponent?: React.ReactNode;
    closeSidePanel: () => void;
};

export const SidePanelLayout: FC<SidePanelLayoutProps> = ({ closeSidePanel, children }) => {
    const isMobile = useMediaQuery({ target: 'tablet' });
    return (
        <>
            {!isMobile ? (
                <Responsive min="frame">
                    <SidePanelLayoutDesktop closeSidePanel={() => closeSidePanel()}>{children}</SidePanelLayoutDesktop>
                </Responsive>
            ) : null}

            {isMobile ? (
                <Responsive max="frame">
                    <SidePanelLayoutMobile closeSidePanel={() => closeSidePanel()}>{children}</SidePanelLayoutMobile>
                </Responsive>
            ) : null}
        </>
    );
};

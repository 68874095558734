import { FC, useEffect, useState } from 'react';
import { TextInput } from '../../../../forms/inputs';
import { CartStoreContext } from '../../../../../lib/state/shop/cart/cart.store';
import {
    StyledDetailsHeader,
    StyledDetailsModalOpener,
    StyledPickupDestination,
    StyledPickupDestinationHeader,
    StyledPickupDestinationsWrapper,
    StyledScrollMoreIndicator,
    StyledSpinnerWrapper,
    StyledUserZipWrapper,
} from './shop-pickup.styled';
import { Button } from '../../../../shared';
import { getPageByDataTypeAliasGeneric } from '../../../../../lib/api';
import { DealershipInformationPage } from '../../../../../lib/api/models/umbraco';
import { Spinner } from '../../../../shared/spinner';
import { ShopPickupMap } from './shop-pickup-map.component';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { SvgIcon } from '../../../../shared/svg-icon';
import { SidePanel } from '../../../../shared/modals/side-panel';
import { SidePanelLayout } from '../../../../shared/modals/side-panel-layout/side-panel-layout.component';
import { OpeningHours } from '../../../../shared/opening-hours/opening-hours.component';

type IProps = {
    updateSelectedDealership: (selectedDealership: DealershipInformationPage) => void;
    submitDelivery: () => void;
};

export const ShopPickup: FC<IProps> = ({ updateSelectedDealership, submitDelivery }) => {
    const { cart } = CartStoreContext.useStoreState((state) => state);
    const [userZip, setUserZip] = useState(cart?.customer?.customerAddress.zipCode);
    const [pickupDestinations, setPickupDestinations] = useState<Array<DealershipInformationPage>>([]);
    const [selectedPickupDestinationId, setSelectedPickupDestinationId] = useState('');
    const [pickupDestinationDetails, setPickupDestinationDetails] = useState<DealershipInformationPage>();

    useEffect(() => {
        if (selectedPickupDestinationId && pickupDestinations) {
            const selected = pickupDestinations.find((x) => x.hovedafdelingId === selectedPickupDestinationId);
            if (selected) {
                updateSelectedDealership(selected);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pickupDestinations, selectedPickupDestinationId]);

    useEffect(() => {
        if (selectedPickupDestinationId && selectedPickupDestinationId.length > 0) submitDelivery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPickupDestinationId]);

    const mapsRender = (status: Status) => {
        switch (status) {
            case Status.LOADING:
                return (
                    <StyledSpinnerWrapper>
                        <Spinner size="large" variant="dark" />
                    </StyledSpinnerWrapper>
                );
            case Status.FAILURE:
                return <p>Error loading google maps</p>;
            case Status.SUCCESS: {
                return (
                    <ShopPickupMap
                        pickupDestinations={pickupDestinations}
                        selectedPickupDestination={selectedPickupDestinationId}
                        setSelectedPickupDestination={(destination) => updateSelectedDestinationFromMap(destination)}
                    />
                );
            }
        }
    };

    useEffect(() => {
        const getAllDealership = async () => {
            const [dealerships, error] = await getPageByDataTypeAliasGeneric<Array<DealershipInformationPage>>('dealershipInformation');
            if (dealerships && !error) {
                const dealershipsWithCorrectDepartment = dealerships.filter(
                    (x) =>
                        x.departments.find((y) => y.departmentType === 'Lager') &&
                        x.hovedafdelingId !== '' &&
                        x.hovedafdelingId !== null &&
                        x.hovedafdelingId !== undefined
                );
                const sorted = dealershipsWithCorrectDepartment.sort(
                    (a, b) => Math.abs(Number(a.zipcode) - Number(userZip)) - Math.abs(Number(b.zipcode) - Number(userZip))
                );
                setPickupDestinations(sorted);
                setSelectedPickupDestinationId(sorted[0].hovedafdelingId);
            }
        };
        getAllDealership();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const zipAsNumber = Number(userZip);
        if (!userZip || userZip.length !== 4 || isNaN(zipAsNumber)) {
            return;
        }
        const sorted = pickupDestinations.sort((a, b) => Math.abs(Number(a.zipcode) - zipAsNumber) - Math.abs(Number(b.zipcode) - zipAsNumber));
        setPickupDestinations(sorted);
        setSelectedPickupDestinationId(sorted[0]?.hovedafdelingId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userZip]);

    const updateSelectedDestinationFromMap = (destinationId: string) => {
        setSelectedPickupDestinationId(destinationId);
        const el = document.getElementById(`destination-${destinationId}`);
        if (el) {
            el.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    const getPartsDepartment = (destination: DealershipInformationPage) => {
        const partsDepartment = destination.departments.find((x) => x.departmentType === 'Lager');
        return partsDepartment ? [{ ...partsDepartment, departmentName: 'Åbningstider' }] : [];
    };

    return (
        <div>
            <StyledUserZipWrapper>
                <TextInput
                    canValidateInputField={false}
                    id="zip-search"
                    isValid={false}
                    type="text"
                    label="POST NR."
                    onChange={(e) => {
                        setUserZip(e.target.value);
                    }}
                    value={userZip}
                />
            </StyledUserZipWrapper>
            <StyledPickupDestinationsWrapper>
                {pickupDestinations.map((x) => {
                    const selected = x.hovedafdelingId === selectedPickupDestinationId;
                    return (
                        <StyledPickupDestination key={x.id} selected={selected} id={`destination-${x.hovedafdelingId}`}>
                            <div>
                                <StyledPickupDestinationHeader>{x.displayName}</StyledPickupDestinationHeader>
                                <p>
                                    {x.address}, {x.zipcode} {x.city}
                                </p>
                                <StyledDetailsModalOpener onClick={() => setPickupDestinationDetails(x)}>
                                    Se info og åbningstider
                                </StyledDetailsModalOpener>
                            </div>
                            <div>
                                <Button variant={selected ? 'light' : 'primary'} onClick={() => setSelectedPickupDestinationId(x.hovedafdelingId)}>
                                    {selected ? (
                                        <span style={{ display: 'flex', gap: '5px', alignItems: 'center', marginRight: '-7px' }}>
                                            <span>Valgt</span>
                                            <SvgIcon iconName="checkmark" />
                                        </span>
                                    ) : (
                                        'Afhent her'
                                    )}
                                </Button>
                            </div>
                        </StyledPickupDestination>
                    );
                })}
                <StyledScrollMoreIndicator />
            </StyledPickupDestinationsWrapper>
            {pickupDestinations.length === 0 ? (
                <StyledSpinnerWrapper>
                    <Spinner size="large" variant="dark" />
                </StyledSpinnerWrapper>
            ) : (
                <Wrapper id="shop-pickup-maps" apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''} render={(status) => mapsRender(status)} />
            )}
            <SidePanel
                isVisible={pickupDestinationDetails !== undefined}
                cancelAction={() => {
                    setPickupDestinationDetails(undefined);
                }}
            >
                <SidePanelLayout
                    closeSidePanel={() => {
                        setPickupDestinationDetails(undefined);
                    }}
                >
                    {pickupDestinationDetails !== undefined ? (
                        <>
                            <StyledDetailsHeader>{pickupDestinationDetails.displayName}</StyledDetailsHeader>
                            <OpeningHours departments={getPartsDepartment(pickupDestinationDetails)} openDepartmentIndex={0} />
                        </>
                    ) : null}
                </SidePanelLayout>
            </SidePanel>
        </div>
    );
};

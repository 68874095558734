import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { VFC } from 'react';
import { Spinner } from '../../../shared/spinner';
import { Map } from '../map/maps.component';
import { MAP_NO_LOCATION_ZOOM_LEVEL, MAP_ZOOM_LEVEL } from '../../../../constants/organization-consts';
import { FindDealershipsStore } from '../../../../lib/state/find-dealership';

const render = (status: Status, center: google.maps.LatLngLiteral, zoomLevel: number) => {
    switch (status) {
        case Status.LOADING:
            return <Spinner size="large" variant="dark" />;
        case Status.FAILURE:
            return <p>Error loading google maps</p>;
        case Status.SUCCESS: {
            return <Map center={center} zoom={zoomLevel} />;
        }
    }
};

type GoogleMapProps = {
    center: google.maps.LatLngLiteral;
};

export const GoogleMap: VFC<GoogleMapProps> = ({ center }) => {
    const { userCurrentLocation } = FindDealershipsStore.useStoreState((state) => state);
    return (
        <Wrapper
            id="ejh-gm"
            apiKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? ''}
            render={(status) => render(status, center, userCurrentLocation ? MAP_ZOOM_LEVEL : MAP_NO_LOCATION_ZOOM_LEVEL)}
        />
    );
};

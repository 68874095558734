import { FC, useEffect, useState } from 'react';
import { StyledButton } from '../step/step.styled';
import { StyledSummaryCard } from '../customer-info-step/customer-info.styled';
import { ServiceAgreementCalculatorModalStore } from '../../../../lib/state/service-agreement-calculator/service-agreement-calculator.store';
import { CompleteCareAgreementOption } from './complete-care-agreement-option/complete-care-agreement-option.component';
import { ServiceCareAgreementOption } from './service-care-agreement-option/service-care-agreement-option.component';
import { WarrantyExtensionAgreementOption } from './warranty-extension-agreement-option/warranty-extension-agreement-option.component';
import { ServiceAgreementCalculatorStepTwo } from '../../../../lib/api/models/umbraco';
import { StyledErrorLabel } from '../license-plate-step/license-plate-step.styled';
import { formatPrice } from '../../../../utils/helpers';

type Props = {
    onNextStepClick: () => void;
    isOpen: boolean;
    isCompleted: boolean;
    stepSettings: ServiceAgreementCalculatorStepTwo;
};

export const ServiceAgreementStep: FC<Props> = ({ onNextStepClick, isOpen, isCompleted, stepSettings }) => {
    const { serviceAgreements, selectedCompleteCare, selectedServiceCare, selectedWarrantyExtension } =
        ServiceAgreementCalculatorModalStore.useStoreState((state) => state);

    const [canValidate, setCanValidate] = useState(false);
    const [valid, setValid] = useState(true);

    useEffect(() => {
        setValid([selectedCompleteCare, selectedServiceCare, selectedWarrantyExtension].some((x) => x));
    }, [selectedCompleteCare, selectedServiceCare, selectedWarrantyExtension]);

    const onClick = () => {
        setCanValidate(true);
        if (valid) {
            onNextStepClick();
        }
    };

    return (
        <>
            {isOpen && !!serviceAgreements && (
                <>
                    {canValidate && !valid && <StyledErrorLabel>Vælg venligst en serviceaftale</StyledErrorLabel>}
                    {serviceAgreements.serviceCare.length > 0 && (
                        <ServiceCareAgreementOption description={stepSettings.serviceCareDescription} agreements={serviceAgreements.serviceCare} />
                    )}
                    {serviceAgreements.completeCare.length > 0 && (
                        <CompleteCareAgreementOption description={stepSettings.completeCareDescription} agreements={serviceAgreements.completeCare} />
                    )}
                    {serviceAgreements.warrantyExtensions.length > 0 && (
                        <WarrantyExtensionAgreementOption
                            description={stepSettings.warrantyExtensionDescription}
                            agreements={serviceAgreements.warrantyExtensions}
                        />
                    )}
                    <StyledButton onClick={onClick} variant="primary">
                        {stepSettings.ctaText}
                    </StyledButton>
                </>
            )}

            {isCompleted && (
                <>
                    {!!selectedCompleteCare && (
                        <StyledSummaryCard>
                            <span>Mercedes-Benz CompleteCare</span>
                            <span>{selectedCompleteCare.months} mdr.</span>
                            <span>{formatPrice(selectedCompleteCare.price)} kr./mdr.</span>
                        </StyledSummaryCard>
                    )}
                    {!!selectedServiceCare && (
                        <StyledSummaryCard>
                            <span>Mercedes-Benz ServiceCare</span>
                            <span>{selectedServiceCare.months} mdr.</span>
                            <span>{selectedServiceCare.totalServiceInspections} services</span>
                            <span>{formatPrice(selectedServiceCare.price)} kr./mdr.</span>
                        </StyledSummaryCard>
                    )}
                    {!!selectedWarrantyExtension && (
                        <StyledSummaryCard>
                            <span>Mercedes-Benz WarrantyExtension</span>
                            <span>+{selectedWarrantyExtension.months} mdr.</span>
                            <span>{formatPrice(selectedWarrantyExtension.price)} kr.</span>
                        </StyledSummaryCard>
                    )}
                </>
            )}
        </>
    );
};
